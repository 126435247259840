'use client';

import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  forwardRef,
  Ref,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

export interface TextareaProps extends Omit<ComponentPropsWithoutRef<'textarea'>, 'cols'> {
  value?: string;
  isError?: boolean;
  isSuccess?: boolean;
  defaultValue?: string;
  showLetterCount?: boolean;
  textareaClassName?: string;
  letterCountClassName?: string;
}

const Textarea = (
  {
    isError,
    isSuccess,
    maxLength,
    className,
    showLetterCount,
    textareaClassName,
    letterCountClassName,
    onChange,
    disabled,
    defaultValue,
    value,
    ...textareaProps
  }: TextareaProps,
  ref: Ref<HTMLTextAreaElement | null>,
) => {
  const textareaElRef = useRef<HTMLTextAreaElement>(null);
  const [letterCount, setLetterCount] = useState(0);

  useImperativeHandle(ref, () => textareaElRef.current);

  useLayoutEffect(() => {
    setLetterCount(textareaElRef.current?.value.length || 0);
  }, []);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    if (onChange) {
      onChange(event);
    }

    setLetterCount(event.target.value.length);
  };

  return (
    <label
      className={classNames(
        'text-field-base textarea h-auto pr-0.5 focus-within:text-field-focus',
        {
          'text-field-error': isError,
          'text-field-success': isSuccess,
          'text-field-disabled': disabled,
        },
        className,
      )}
    >
      <textarea
        ref={textareaElRef}
        className={classNames(
          'textarea-field custom-scrollbar resize-none overflow-x-hidden overflow-y-scroll pr-0.5',
          {
            'cursor-not-allowed': disabled,
          },
          textareaClassName,
        )}
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        disabled={disabled}
        rows={3}
        defaultValue={defaultValue}
        {...textareaProps}
      />

      {showLetterCount && (
        <div className={classNames('textarea-letter-count', letterCountClassName)}>
          {maxLength ? `${letterCount}/${maxLength}` : letterCount}
        </div>
      )}
    </label>
  );
};

export default forwardRef(Textarea);
