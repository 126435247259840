import './SmallWidget.scss';
import classNames from 'classnames';

import RatingItems from '../RatingItems/RatingItems';
import { ReactComponent as LogoSvg } from './icons/logo.svg';
import { WidgetProps } from './MainWidget';

const Level = {
  0: 'N/A',
  1: 'Low',
  2: 'Low',
  3: 'Medium',
  4: 'Good',
  5: 'Excellent',
};

type AverageRating = 0 | 1 | 2 | 3 | 4 | 5;

const SmallWidget = ({ theme, rating, isLink = true }: WidgetProps) => {
  const isDark = theme === 'dark';
  const WrapperComponent = isLink ? 'a' : 'div';

  return (
    <WrapperComponent
      href={isLink ? `https://magic.store/app/${rating.appLink.attributes.link}` : undefined}
      target={isLink ? '_blank' : undefined}
      rel={isLink ? 'noreferrer' : undefined}
      className={classNames('small-widget', {
        'light-theme': !isDark,
        'dark-theme': isDark,
      })}
    >
      <p
        className={classNames('level-text', {
          'light-theme': !isDark,
          'dark-theme': isDark,
        })}
      >
        {Level[Math.round(rating?.attributes?.averageRating || 0) as AverageRating]}
      </p>
      <div className="rating-container">
        <RatingItems
          score={Math.round(rating?.attributes?.averageRating || 0)}
          itemClassName={classNames('rating-item', {
            'light-theme': !isDark,
            'dark-theme': isDark,
          })}
          className="gap-1"
        />
      </div>
      <div className="logo-container">
        <LogoSvg className="logo" />
      </div>
    </WrapperComponent>
  );
};

export default SmallWidget;
