export const getPagination = ({
  totalPages = 1,
  currentPage = 1,
  numberOfVisiblePages = 3,
}: {
  totalPages?: number;
  currentPage?: number;
  numberOfVisiblePages?: number;
}) => {
  if (!totalPages) {
    return {
      pages: [],
      firstPage: currentPage,
      lastPage: currentPage,
      isLastPage: true,
      isFirstPage: true,
    };
  }

  const firstPage = 1;
  const lastPage = totalPages;
  let hasStartSkip = false;
  let hasEndSkip = false;
  let start = 0;
  let length = numberOfVisiblePages;

  if (numberOfVisiblePages + 4 >= totalPages) {
    length = totalPages;
    start = 1;
  } else {
    const offset = Math.floor(numberOfVisiblePages / 2);

    hasStartSkip = currentPage - offset - 2 > firstPage;
    hasEndSkip = currentPage + offset + 2 < lastPage;

    if (hasStartSkip && hasEndSkip) {
      start = currentPage - offset;
    } else {
      start = hasStartSkip ? lastPage - numberOfVisiblePages - 1 : firstPage;
      length = numberOfVisiblePages + 2;
    }
  }

  const pages = [
    ...(hasStartSkip ? [firstPage, null] : []),
    ...Array.from({ length }, (_, i) => i + start),
    ...(hasEndSkip ? [null, lastPage] : []),
  ];

  return {
    pages,
    firstPage,
    lastPage,
    isLastPage: currentPage >= lastPage,
    isFirstPage: currentPage <= firstPage,
  };
};
