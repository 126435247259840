import './CompactWidget.scss';
import classNames from 'classnames';

import RatingItems from '../RatingItems/RatingItems';
import { ReactComponent as LogoFullSvg } from './icons/logo-full.svg';
import { WidgetProps } from './MainWidget';

const CompactWidget = ({ theme, rating, isLink = true }: WidgetProps) => {
  const isDark = theme === 'dark';
  const WrapperComponent = isLink ? 'a' : 'div';

  return (
    <WrapperComponent
      className={classNames('compact-widget', {
        'light-theme': !isDark,
        'dark-theme': isDark,
      })}
      href={isLink ? `https://magic.store/app/${rating.appLink.attributes.link}` : undefined}
      target={isLink ? '_blank' : undefined}
      rel={isLink ? 'noreferrer' : undefined}
    >
      <div className="logo-container">
        <LogoFullSvg
          className={classNames('logo', { 'light-theme': !isDark, 'dark-theme': isDark })}
        />
      </div>
      <div
        className={classNames('rating-container', { 'light-theme': !isDark, 'dark-theme': isDark })}
      >
        <RatingItems score={Math.round(rating?.attributes?.averageRating || 0)} />
      </div>
      <div className="info-container">
        <p
          className={classNames('average-rating', { 'light-theme': !isDark, 'dark-theme': isDark })}
        >
          Avg. Rating{' '}
          <span className="rating-value">{rating?.attributes?.averageRating?.toFixed(2)}</span>
        </p>
        <p className={classNames('review-count', { 'light-theme': !isDark, 'dark-theme': isDark })}>
          <span className="rating-value">{rating?.attributes?.countReviews}</span> Reviews
        </p>
      </div>
    </WrapperComponent>
  );
};

export default CompactWidget;
